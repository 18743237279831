.home {
  box-sizing: border-box;
  margin: 2rem auto 0;
  max-width: 1224px;
  padding: 1rem 3rem;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    border-color: #658DFD;
    border-style: dashed;
    border-width: 0;
    content: '';
    position: absolute;
  }

  &::before {
    border-top-width: 1px;
    left: 50%;
    top: 1rem;
    transform: translateX(-50%);
    width: 100vw;
  }

  &::after {
    border-left-width: 1px;
    left: 2rem;
    top: -2rem;
    height: 100%;
  }

  h2 {
    color: #265CFF;
    font-size: 28px;
    font-weight: normal;
    margin: 2rem 0;
  }

  .columns {
    display: flex;
    flex-wrap: wrap;
    padding-left: 2rem;
    justify-content: space-between;

    @media (max-width: 520px) {
      flex-direction: column;
    }

    .column {
      flex-basis: calc((100% / 3) - 1rem);
      margin-bottom: 2rem;

      @media (max-width: 520px) {
        flex-basis: 100%;
      }

      span {
        color: #265CFF;
        font-size: 16px;
      }

      h3 {
        font-size: 28px;
        font-weight: normal;
        margin-bottom: 0;
        margin-top: 0.5rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.5;
        margin: 0.25rem 0;
      }
    }
  }
}
  