.menu {
  background-color: #1f2021;
  color: #FFFFFF;
  height: 100vh;
  max-width: 400px;
  overflow: hidden;
  padding-left: 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 0;
  transition: width var(--menu-animation-duration) ease-in-out, padding var(--menu-animation-duration) ease-in-out;
  width: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;

  &.open {
    padding-left: 1rem;
    width: 100%;
  }

  & > * {
    width: 400px;
  }

  .menu-header {
    align-items: center;
    display: flex;
    height: 45px;
    position: relative;
  
    h2 {
      margin: 0;
    }

    &::after {
      background-color: #FFFFFF;
      bottom: 0;
      content: '';
      height: 1px;
      left: -1rem; // 1rem accounts for the left-padding when .open
      position: absolute;
      width: calc(100% + 1rem); // 1rem accounts for the left-padding when .open
    }
  }

  .close-icon {
    border: none;
    background-color: transparent;
    cursor: pointer;
    height: var(--icon-size);
    position: absolute;
    right: 3px;
    top: 3px;
    width: var(--icon-size);
  }
}

nav {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    display: inline-block;
    text-decoration: none;
    color: #FFFFFF;
  }
}

.primary-links {
  font-size: 28px;
  padding-top: 1rem;

  a {
    margin: 0.5rem 0;
    padding: 6px 3px;
  }
}

.secondary-links {
  box-sizing: border-box;
  font-size: 16px;
  margin-top: auto;
  padding: 1rem 1rem 1rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 0 1rem;

    a {
      padding: 0.5rem 0.5rem;
      text-align: center;
    }
  }
}
